import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentPageTitle = 'Dashboard';

  appPages = [
    {
      title: 'Dashboard',
      url: '',
    },
  ];
  constructor(public nav:NavController,public api:ApiService) {
    //this.nav.navigateRoot('course');
  }
  dashboard(){
    this.nav.navigateRoot('home-partner');
  }
  studentRegister(){
    this.nav.navigateRoot('student-register');
  }
  approveStudent(){
    this.nav.navigateRoot('approved-students');
  }
  disApproveStudent(){
    this.nav.navigateRoot('disapprove-students');
  }
  testResults(){
    this.nav.navigateRoot('test-results');
  }

  //!admin pannel
  addFrenchise(){
    this.nav.navigateRoot('add-frenchise');
  }
  viewFrenchise(){
    this.nav.navigateRoot('view-frenchise');
  }
}
