import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu: any = true;
  public showAdminMenu: any = true;
  public baseUrl: any = "https://api.aicasindia.in/api/";
  // public baseUrl: string = "http://localhost:2141/api/";
  constructor(private afs: AngularFirestore, public http: HttpClient) { }

  // saveFranchiseInquiry(data) {
  //   return this.afs.collection('franchisesInquiry').doc().set(data);
  //  }
  //  saveStudentInquiry(data) {
  //   return this.afs.collection('studentsInquiry').doc().set(data);
  //  }
  //!node js api
  saveFranchiseInquiry(data) {
    return this.http.post(this.baseUrl + "inquiry_franchises/add_inquiry_franchises", data);
  }
  saveStudentInquiry(data) {
    return this.http.post(this.baseUrl + "inquiry_students/add_inquiry_students", data);
  }
  //!get banner
  getBanner() {
    return this.http.get(this.baseUrl + "banner/get_banner");
  }
  //!get gallery
  getGallery() {
    return this.http.get(this.baseUrl + "gallery/get_galleryImage");
  }

   //! get Youtube links
   getLink() {
    return this.http.get(this.baseUrl + "youtube_links/getLinks");
  }
}
