import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'why-aicas',
    loadChildren: () => import('./why-aicas/why-aicas.module').then( m => m.WhyAicasPageModule)
  },
  {
    path: 'certification',
    loadChildren: () => import('./certification/certification.module').then( m => m.CertificationPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'online-exam',
    loadChildren: () => import('./online-exam/online-exam.module').then( m => m.OnlineExamPageModule)
  },
  {
    path: 'verify-certificate',
    loadChildren: () => import('./verify-certificate/verify-certificate.module').then( m => m.VerifyCertificatePageModule)
  },
  {
    path: 'view-director',
    loadChildren: () => import('./view-director/view-director.module').then( m => m.ViewDirectorPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'franchises-form',
    loadChildren: () => import('./franchises-form/franchises-form.module').then( m => m.FranchisesFormPageModule)
  },
  {
    path: 'student-form',
    loadChildren: () => import('./student-form/student-form.module').then( m => m.StudentFormPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./partner/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home-partner',
    loadChildren: () => import('./partner/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'add-student',
    loadChildren: () => import('./partner/add-student/add-student.module').then( m => m.AddStudentPageModule)
  },
  {
    path: 'student-register',
    loadChildren: () => import('./partner/student-register/student-register.module').then( m => m.StudentRegisterPageModule)
  },
  {
    path: 'approved-students',
    loadChildren: () => import('./partner/approved-students/approved-students.module').then( m => m.ApprovedStudentsPageModule)
  },
  {
    path: 'disapprove-students',
    loadChildren: () => import('./partner/disapprove-students/disapprove-students.module').then( m => m.DisapproveStudentsPageModule)
  },
  {
    path: 'test-results',
    loadChildren: () => import('./partner/test-results/test-results.module').then( m => m.TestResultsPageModule)
  },
  {
    path: 'admin-home',
    loadChildren: () => import('./admin/admin-home/admin-home.module').then( m => m.AdminHomePageModule)
  },
  {
    path: 'admin-login',
    loadChildren: () => import('./admin/admin-login/admin-login.module').then( m => m.AdminLoginPageModule)
  },
  {
    path: 'add-frenchise',
    loadChildren: () => import('./admin/add-frenchise/add-frenchise.module').then( m => m.AddFrenchisePageModule)
  },
  {
    path: 'view-frenchise',
    loadChildren: () => import('./admin/view-frenchise/view-frenchise.module').then( m => m.ViewFrenchisePageModule)
  },
  {
    path: 'course',
    loadChildren: () => import('./course/course.module').then( m => m.CoursePageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
